import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginright: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  })
);

const NotFound: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h1" className="error-num" color="primary">
        404
      </Typography>
      <Typography variant="h3" color="primary">
        Sorry, Page Not Found
      </Typography>
    </div>
  );
};

export default NotFound;
