import { curry } from 'ramda';
import { ConnectionData } from '../logic/ConnectionLogic';
import { getProviderMapping, MappingProps } from '../logic/EditFarmLogic';
import { CategoryName, ConnectionStatus, ProviderCode } from './Enum';
import React from 'react';
import debug from 'debug';

export const checkVarEqual = curry((x, y) => {
  return x === y;
});

const loggerCache: { [k: string]: debug.Debugger } = {};

export function getLogger(name: string) {
  if (Object.keys(loggerCache).indexOf(name) === -1) {
    loggerCache[name] = debug(`agnz:${name}`);
  }
  return loggerCache[name];
}

export function enableLogging() {
  debug.enable('agnz:*');
}

export const removeDuplicateString = (a: Array<string>): Array<string> => {
  const uniqSet = new Set(a);
  const uniqArray = [...uniqSet.values()];
  return uniqArray;
};

export const preventDefault = (event: React.SyntheticEvent) =>
  event.preventDefault();

export const getConnectedProvider = (
  connectionData: Array<ConnectionData>
): Array<ProviderCode> => {
  const connected = connectionData?.filter(
    (d) => d.status === ConnectionStatus.connected
  );
  return connected ? connected.map((c) => c.code) : [];
};

export const getMappingString = async (
  provider: ProviderCode
): Promise<string> => {
  let mappingsResult: Array<MappingProps> = [];
  switch (provider) {
    case ProviderCode.fonterra:
      mappingsResult = await getProviderMapping(
        provider,
        CategoryName.milkProduction
      );
      return mappingsResult.map((m) => m.resourceId).join('; ');
    case ProviderCode.lic: {
      mappingsResult = await getProviderMapping(provider, CategoryName.herd);
      const mindaPastureResult = await getProviderMapping(
        provider,
        CategoryName.pasture
      );
      const mappingsResultString: string = mappingsResult
        .map((m) => m.resourceId)
        .join('; ')
        .concat('\n');
      const mindaPastureResultString = mindaPastureResult
        .map((m) => m.resourceId)
        .join('; ');

      return mappingsResultString.concat(mindaPastureResultString);
    }
    case ProviderCode.ravensdown:
      mappingsResult = await getProviderMapping(provider, CategoryName.herd);
      return mappingsResult.map((m) => m.resourceId).join('; ');

    case ProviderCode.figured:
      mappingsResult = await getProviderMapping(
        provider,
        CategoryName.Accounts
      );
      return mappingsResult.map((m) => m.resourceId).join('; ');

    case ProviderCode.farmax:
      mappingsResult = await getProviderMapping(
        provider,
        CategoryName.supplementaryFeed
      );
      return mappingsResult.map((m) => m.resourceId).join('; ');
    default:
      return '';
  }
};

export function connectionDataIsConnected(
  connectionData: ConnectionData[],
  providerCode: ProviderCode | string
): boolean {
  const connectedProviders = connectionData.filter(
    (provider) => provider.status === 1
  );
  return connectedProviders.some((provider) => provider.code === providerCode);
}

export function connectionDataBothConnected(
  connectionData: ConnectionData[],
  provider1: ProviderCode,
  provider2: ProviderCode
): boolean {
  const connectedProviders = connectionData.filter(
    (provider) => provider.status === 1
  );
  if (
    connectedProviders.some((provider) => provider.code === provider1) &&
    connectedProviders.some((provider) => provider.code === provider2)
  ) {
    return true;
  } else {
    return false;
  }
}

export const getProviderNameByCode = (providerCode: ProviderCode | string) => {
  switch (providerCode) {
    case ProviderCode.fonterra:
      return 'Farm Source';
    case ProviderCode.lic:
      return 'Minda';
    case ProviderCode.farmax:
      return 'Farmax';
    case ProviderCode.figured:
      return 'Figured';
    case ProviderCode.paysauce:
      return 'PaySauce';
    case ProviderCode.ravensdown:
      return 'Ravensdown';
    default:
      return '';
  }
};
