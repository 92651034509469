import { CategoryName, ProviderCode } from '../Enum';
import * as dataService from '../../services/dataService';
import { getLogger } from '../Utilities';
import { GetProviderResource } from '../context/SelectedFarmProvider';
import { UserResource } from '../../services/dataService';

const logger = getLogger('getProviderResource');
const callGetProviderResource = async (
  provider: ProviderCode,
  farmId: string | number
): Promise<GetProviderResource[]> => {
  switch (provider) {
    case ProviderCode.lic: {
      logger('Get LIC herd and pasture');
      const herdResult: Promise<Array<UserResource>> =
        dataService.getProviderResource({
          farmId,
          provider,
          categoryName: CategoryName.herd,
        });
      const pastureResult: Promise<Array<UserResource>> =
        dataService.getProviderResource({
          farmId,
          provider,
          categoryName: CategoryName.farm,
        });
      const [herd, pasture] = await Promise.all([herdResult, pastureResult]);
      return [
        { provider, categoryName: 'Herd', resources: herd },
        { provider, categoryName: 'Pasture', resources: pasture },
      ];
    }
    case ProviderCode.fonterra: {
      logger('Get Fonterra farm source');
      const farmSourceResult: Array<UserResource> =
        await dataService.getProviderResource({
          farmId,
          provider,
          categoryName: 'farm',
        });
      return [
        {
          provider,
          categoryName: 'Milk Production',
          resources: farmSourceResult,
        },
      ];
    }
    case ProviderCode.farmax: {
      logger('Get Farmax farm source');
      const farmaxResult = await dataService.getProviderResource({
        farmId,
        provider,
        categoryName: 'farm',
      });
      return [{ provider, categoryName: 'Farm', resources: farmaxResult }];
    }
    case ProviderCode.figured: {
      logger('Get Figured farm source');
      const figuredResult: Array<UserResource> =
        await dataService.getProviderResource({
          farmId,
          provider,
          categoryName: 'farm',
        });
      return [{ provider, categoryName: 'Farm', resources: figuredResult }];
    }
    default: {
      logger('No provider returning empty array');
      return [];
    }
  }
};
export default callGetProviderResource;
