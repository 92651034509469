import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pagePadding: {
      position: 'relative' /* important for back button placement */,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

/**
 * Providers max width and side gutters for content but no vertical padding
 */
const PagePadding: React.FC = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.pagePadding} data-testid={'page-padding'}>
      {props.children}
    </div>
  );
};
export default PagePadding;
