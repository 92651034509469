import {
  AccountInfo,
  EndSessionRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { clearInsightAuth, setInsightAuth, trackEvent } from '../AppInsights';
import { AppInsightsEvent } from '../Enum';
import { AuthenticationResult } from '@azure/msal-common';
import {
  auth,
  loginScopes,
  msalInstance,
  resetPasswordMsalInstance,
} from '../auth/msal';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useErrorHandler } from 'react-error-boundary';
import { useAccount } from './AccountProvider';
import { getLogger } from '../Utilities';
import PageLoader from '../../components/loaders/PageLoader';

const AuthContext = createContext({} as AuthContextProps);

export type ConnectAccountInfo = AccountInfo & {
  idTokenClaims: {
    emails?: string[];
    'signInNames.emailAddress'?: string;
    tfp?: string;
    extension_UserId?: string;
  };
};

interface ConnectAccounts {
  minda: ConnectAccountInfo | null;
  farmSource: ConnectAccountInfo | null;
}

interface AuthContextProps {
  isAuthenticated: boolean;
  account: ConnectAccountInfo | null;
  connectedAccounts: ConnectAccounts;
  loading: boolean;
  logout: () => void;
  login: () => void;
  isLoggingOut: boolean;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

type RedirectResponse =
  | (AuthenticationResult & { idTokenClaims: { tfp: string } })
  | null;

const log = getLogger('AuthProvider');

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const handleError = useErrorHandler();
  const { account, setAccount } = useAccount();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldFetch, setShouldFetch] = useState<boolean>(true);
  const [connectedAccounts, setConnectedAccounts] = useState<ConnectAccounts>({
    minda: null,
    farmSource: null,
  });
  const getAccount = () => {
    log('Get account');
    const currentAccounts =
      msalInstance.getAllAccounts() as unknown as ConnectAccountInfo[];
    if (currentAccounts === null) {
      return null;
    }
    if (currentAccounts.length === 1) {
      return currentAccounts[0];
    } else {
      const selectedAccount = currentAccounts.filter((a) => {
        return !!(
          a.idTokenClaims &&
          a.idTokenClaims.tfp &&
          a.idTokenClaims.tfp === auth.names.signUpSignIn
        );
      });
      return selectedAccount ? selectedAccount[0] : currentAccounts[0];
    }
  };

  const login = useCallback(() => {
    log('Login');
    try {
      setLoading(true);
      trackEvent(AppInsightsEvent.evtSigninRedirect);
      const redirectRequest: RedirectRequest = {
        scopes: loginScopes,
      };
      setIsLoggingIn(true);
      msalInstance
        .loginRedirect(redirectRequest)
        .catch((err) => handleError(err));
    } catch (e) {
      handleError(e);
      setLoading(false);
    }
  }, [setLoading, handleError]);

  const logout = useCallback(() => {
    log('Logout');
    setIsLoggingOut(true);
    const userId =
      account && account.idTokenClaims
        ? account.idTokenClaims.extension_UserId
        : null;
    trackEvent(AppInsightsEvent.evtClickSignout, { userId });

    setAccount(null);
    setIsAuthenticated(false);
    clearInsightAuth();
    const logOutRequest: EndSessionRequest = {
      account: account,
    };
    msalInstance
      .logoutRedirect(logOutRequest)
      ?.catch((err) => handleError(err));
  }, [account, handleError, setAccount]);

  const init = useCallback(async () => {
    log('Init');
    //wait for redirect promise to finish
    const response: RedirectResponse =
      (await msalInstance.handleRedirectPromise()) as RedirectResponse;
    if (response?.idTokenClaims?.tfp) {
      const tfp = response.idTokenClaims.tfp;
      switch (tfp) {
        case auth.names.minda:
          setConnectedAccounts({
            ...connectedAccounts,
            minda: response.account as unknown as ConnectAccountInfo,
          });
          break;
        case auth.names.farmsource:
          setConnectedAccounts({
            ...connectedAccounts,
            farmSource: response.account as unknown as ConnectAccountInfo,
          });
          break;
      }
    }

    //check if user is authenticated
    const account = getAccount();
    if (account) {
      msalInstance.setActiveAccount(account);
      setInsightAuth(
        account.localAccountId,
        account.idTokenClaims.extension_UserId
      );
      setIsAuthenticated(true);
      setAccount(account);
    } else {
      login();
    }
  }, [connectedAccounts, login, setAccount]);

  useDeepCompareEffect(
    function setup() {
      if (!loading && shouldFetch) {
        log('Setup');
        setLoading(true);
        setShouldFetch(false);
        init()
          .catch((error) => {
            if (
              error &&
              error.errorMessage &&
              error.errorMessage.indexOf('AADB2C90118') > -1
            ) {
              // Password reset policy/authority
              resetPasswordMsalInstance
                .loginRedirect()
                .catch((err) => handleError(err));
            } else {
              handleError(error);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [connectedAccounts, handleError, loading, shouldFetch]
  );

  const value = useMemo(() => {
    return {
      isAuthenticated,
      account,
      loading,
      logout,
      login,
      isLoggingOut,
      connectedAccounts,
    };
  }, [
    account,
    connectedAccounts,
    isAuthenticated,
    isLoggingOut,
    loading,
    login,
    logout,
  ]);

  if (loading || isLoggingOut || isLoggingIn) {
    const message = isLoggingIn
      ? 'Logging in...'
      : isLoggingOut
      ? 'Logging out...'
      : '';
    const id = isLoggingIn
      ? 'page-loading-logging-in'
      : isLoggingOut
      ? 'page-loading-logging-out'
      : 'page-loading';
    return <PageLoader data-testid={id} message={message} />;
  }
  const noAccount = (
    <PageLoader
      data-testid={'account-loading'}
      message={'Loading account...'}
    />
  );
  return (
    <AuthContext.Provider value={value}>
      {account && isAuthenticated ? children : noAccount}
    </AuthContext.Provider>
  );
};
const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, useAuth };
