interface E {
  target: { state: string };
}
interface Registration {
  waiting: {
    addEventListener: (s: string, fn: (event: E) => void) => void;
    postMessage: (obj: { type: string }) => void;
  };
}
const SwConfig = {
  onUpdate: (registration: Registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
};

export default SwConfig;
//re-register the service worker and refresh the page afterwards.
