import { createMuiTheme } from '@material-ui/core/styles';
import './font.scss';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#084D68',
      contrastText: '#fff',
    },
    secondary: {
      main: '#4A90E2',
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#606266',
    },
    success: { main: '#76BE48' },
  },
  typography: {
    fontFamily: 'Assistant',
  },
  listItemText: {
    fontSize: '20px',
  },
  button: {
    fontFamily: 'Assistant',
  },
  overrides: {
    MuiInput: {
      underline: {
        borderBottom: 'none',
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
        '&:after': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: 'none',
        },
        '&:before': {
          // The MUI source seems to use this but it doesn't work
          borderBottom: 'none',
        },
        '& .Mui-error': {
          borderColor: '1px red',
        },
      },
    },
  },
});
