import { curry } from 'ramda';
import axios from '../utilities/Axios';
import {
  ConnectionStatus,
  ProviderCode,
  ShareDataName,
} from '../utilities/Enum';
import farmIqImg from '../assets/FarmIQ.png';
import mindaImg from '../assets/Minda.png';
import farmSourceImg from '../assets/FarmSource.png';
import figuredImg from '../assets/Figured.png';
import hawkeyeImg from '../assets/Hawkeye.png';
import farmaxImg from '../assets/Farmax.png';
import paysauceImg from '../assets/PaySauce.png';
import nzxImg from '../assets/NZX.png';
import rezareImg from '../assets/Rezare.png';
import { getLogger } from '../utilities/Utilities';

export interface FarmData {
  id: number;
  name: string;
}

export interface ConnectionData {
  code: ProviderCode;
  name: string;
  status: ConnectionStatus;
}

export interface ProviderData {
  code: ProviderCode;
  name: string;
  status: ConnectionStatus;
  isMappable: boolean;
}

export interface FarmConnectionData {
  id: number;
  name: string;
  connections: Array<ConnectionData>;
}

export interface Provider {
  providerCode: string;
  dataType: string[];
}

export const getFarmData = async (): Promise<Array<FarmData>> => {
  getLogger('getFarmData')('Fetch /farms');
  return await axios.get('/farms').then((response) => {
    return response.data;
  });
};

export const getConnectionData = async (
  farmId: string
): Promise<FarmConnectionData> => {
  getLogger('getConnectionData')('Fetch /farms/' + farmId);
  return await axios.get(`/farms/${farmId}`).then((response) => {
    return response.data;
  });
};

export const connectProvider = async (
  provider: string,
  providerToken: string
): Promise<Array<FarmData>> => {
  getLogger('connectProvider')('Fetch /connectprovider ' + provider);
  return await axios
    .post('/connectprovider', {
      provider: provider,
      token: providerToken,
    })
    .then((response) => {
      return response.data;
    });
};

export const getProviderData = async (): Promise<Array<ProviderCode>> => {
  getLogger('getProviderData')('/Fetch /providers');
  return await axios.get(`/data-providers`).then((response) => {
    const mappableProviderData: Array<ProviderData> = response.data.filter(
      (idp: ProviderData) => idp.isMappable
    );
    let mappableProvider: Array<ProviderCode> = [];
    if (mappableProviderData) {
      mappableProvider = mappableProviderData.map((mp) => mp.code);
    }
    return mappableProvider;
  });
};

export const getDataTypes = curry(
  (providers: Array<Provider>, providerCode: ProviderCode) => {
    const provider = providers.filter((p) => p.providerCode === providerCode);
    return provider.length > 0 ? provider[0].dataType : [];
  }
);

export const getDisplayNameFromCode = (providerCode: ProviderCode): string => {
  switch (providerCode) {
    case ProviderCode.farmax:
      return 'Farmax';
    case ProviderCode.farmiq:
      return 'FarmIQ';
    case ProviderCode.fonterra:
      return 'Farm Source';
    case ProviderCode.lic:
      return 'Minda';
    case ProviderCode.figured:
      return 'Figured';
    case ProviderCode.ravensdown:
      return 'Hawkeye';
    case ProviderCode.paysauce:
      return 'Paysauce';
    default:
      return 'unknown';
  }
};

export const getIconFromCode = (providerCode: ProviderCode): string => {
  switch (providerCode) {
    case ProviderCode.farmax:
      return farmaxImg;
    case ProviderCode.farmiq:
      return farmIqImg;
    case ProviderCode.fonterra:
      return farmSourceImg;
    case ProviderCode.lic:
      return mindaImg;
    case ProviderCode.figured:
      return figuredImg;
    case ProviderCode.ravensdown:
      return hawkeyeImg;
    case ProviderCode.paysauce:
      return paysauceImg;
    case ProviderCode.nzx:
      return nzxImg;
    case ProviderCode.rezare:
      return rezareImg;
    default:
      return 'unknown';
  }
};

export const getDescriptionForShare = (
  shareDataName: ShareDataName
): string[] => {
  switch (shareDataName) {
    case ShareDataName.animalHealth:
      return [
        'Animal health data to be shared:',
        'Animal treatment records including dosages and withholding times.',
      ];
    case ShareDataName.milkProductionQuality:
      return [
        'Milk Production & Quality data to be shared:',
        '• Litres',
        '• kgMS',
        '• Fat%',
        '• Protein%',
        '• Somatic cell count (SCC)',
        'at a “per tanker pickup” level of granularity.',
      ];
  }
};
