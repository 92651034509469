import { useEffect, useState } from 'react';

import { useAuth } from '../context/AuthProvider';
import { createConversationToken, getUser } from '../../services/crmService';
import { getLogger } from '../Utilities';

const log = getLogger('useHubspot');

declare const window: Window & {
  _hsq: (string | { email: string })[][];
  hsConversationsSettings: {
    loadImmediately?: boolean;
    identificationToken?: string;
    identificationEmail?: string;
  };
  hsConversationsOnReady: (() => void)[];
  HubSpotConversations: { widget: { load: () => void } };
};

const useHubspot = () => {
  const { isAuthenticated, account } = useAuth();

  const email =
    account?.idTokenClaims?.['signInNames.emailAddress'] ??
    account?.idTokenClaims?.emails?.[0] ??
    'anon';
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    log('Adding scripts to document');
    // Don't auto-load the chat box
    window.hsConversationsSettings = {
      loadImmediately: false,
    };

    window.hsConversationsOnReady = [
      () => {
        setHasLoaded(true);
      },
    ];

    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/6566723.js';
    script.async = true;
    script.id = 'hs-script-loader';

    log('Add script to dom');
    document.body.appendChild(script);

    return () => {
      log('Remove script to dom');
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    };
  }, []);

  useEffect(() => {
    const setConversationVisitorIdentity = async () => {
      try {
        log('Try get visitor identity');
        const user = email ? await getUser(email) : null;

        // @NOTE - Make sure the exist in CRM, before requesting for identification token, avoid auto-create
        //       - `createConversationToken` auto-create a contact if it's not in the HS "Contact" list
        if (user && user.contact_id) {
          const identificationToken = await createConversationToken(email);
          if (identificationToken) {
            log('Setting identity token: ' + email);
            window.hsConversationsSettings = {
              identificationEmail: email,
              identificationToken,
            };
          }
        } else {
          log('Skipping identity token');
        }
      } catch (error) {
        log('Hubspot error fetching user!');
        console.warn('Non-fatal hubspot error', error);
      } finally {
        log('Call load on hubspot widget');
        window.HubSpotConversations.widget.load();
      }
    };

    const setTrackingVisitorIdentity = () => {
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        'identify',
        {
          email,
        },
      ]);
    };

    if (isAuthenticated && hasLoaded) {
      log('isAuthenticated and has loaded');
      setTrackingVisitorIdentity();
      setConversationVisitorIdentity().catch((err) => console.error(err));
    } else {
      log('Not authenticated and loaded');
    }
  }, [email, hasLoaded, isAuthenticated]);
};

export default useHubspot;
