import React, { createContext, useMemo, useState } from 'react';
import { ConnectAccountInfo } from './AuthProvider';
interface AccountContextProps {
  account: ConnectAccountInfo | null;
  setAccount: (c: ConnectAccountInfo | null) => void;
}
const AccountContext = createContext({} as AccountContextProps);
const AccountProvider: React.FC = ({ children }) => {
  const [account, setAccount] = useState<ConnectAccountInfo | null>(null);
  const value = useMemo(() => {
    return {
      account,
      setAccount,
    };
  }, [account]);
  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  );
};
const useAccount = () => React.useContext(AccountContext);
export { AccountProvider, useAccount };
