import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Spinner from './Spinner';

const useStyles = makeStyles((theme) =>
  createStyles({
    pageLoaders: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      alignContent: 'center',
    },
    footer: {
      paddingTop: theme.spacing(4),
    },
  })
);

const PageLoader: React.FC<{ message?: string; ['data-testid']?: string }> = (
  props
) => {
  const styles = useStyles();
  return (
    <div
      className={styles.pageLoaders}
      data-testid={props['data-testid'] ?? 'loader'}
    >
      <div>
        <Spinner size={'lg'} />
      </div>
      {props.message ? (
        <footer className={styles.footer}>{props.message}</footer>
      ) : undefined}
    </div>
  );
};
export default PageLoader;
