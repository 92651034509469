import { AxiosRequestConfig } from 'axios';
import { loginScopes, msalInstance } from './msal';
import { AuthenticationResult } from '@azure/msal-common';
import { getLogger } from '../Utilities';
export async function msalInterceptor(config: AxiosRequestConfig) {
  const logger = getLogger('msalInterceptor');
  await msalInstance
    .acquireTokenSilent({ scopes: loginScopes })
    .then((result: AuthenticationResult) => {
      if (result.accessToken) {
        config.headers.Authorization = `Bearer ${result.accessToken}`;
      } else {
        return msalInstance.acquireTokenRedirect({
          scopes: loginScopes,
        });
      }
    })
    .catch((error) => {
      logger('MSAL Interceptor error: ' + error);
      return msalInstance.acquireTokenRedirect({
        scopes: loginScopes,
      });
    });
  return config;
}
