import axios, { AxiosInstance } from 'axios';

import config from '../config';
import { getLogger } from '../utilities/Utilities';
import { msalInterceptor } from '../utilities/auth/interceptor';

const axiosInstance: () => AxiosInstance = () => {
  const instance = axios.create({
    baseURL: config.api.crmUrl,
  });
  instance.defaults.timeout = 30000;
  instance.interceptors.request.use(msalInterceptor, (error) => {
    getLogger('msalInterceptor')('ERROR ' + error);
    throw error;
  });
  return instance;
};

/**
 * @TODO security
 */
export const createConversationToken = async (email: string) => {
  try {
    getLogger('crmService.createConversationToken')(
      'Fetch /conversation ' + email
    );
    const conversationToken = await axiosInstance().post(
      '/conversation',
      {
        email,
      },
      {
        headers: {
          'correlation-id': 'webapp',
        },
      }
    );

    return conversationToken.data && conversationToken.data.token
      ? conversationToken.data.token
      : '';
  } catch (error) {
    getLogger('crm:createConversationToken')(error);
  }
};

export const getUser = async (email: string) => {
  try {
    getLogger('crmService.getUser')('Fetch /user/' + email);
    const user = await axiosInstance().get(`/user/${email}`, {
      headers: {
        'correlation-id': 'webapp',
      },
    });

    return user && user.data ? user.data : '';
  } catch (error) {
    getLogger('crm:getUser')(error);
  }
};
