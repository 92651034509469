import { ListItemLink } from '../listItemLink/ListItemLink';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { SvgIcon } from '../svgIcon/SvgIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import React from 'react';

const HeaderList: React.FC<{
  onClickDashboardHandler: () => void;
  onClickHelpHandler: () => void;
  onClickLogoutHandler: () => void;
}> = (props) => {
  return (
    <List>
      <ListItemLink to="/connection" key="Connection">
        <ListItemIcon>
          <SvgIcon iconName="link" />
        </ListItemIcon>
        <ListItemText primary="Farm Data" />
      </ListItemLink>
      <ListItem button key="Dashboard" onClick={props.onClickDashboardHandler}>
        <ListItemIcon>
          <SvgIcon iconName="farm" />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItemLink to="/profile" key="Profile">
        <ListItemIcon>
          <SvgIcon iconName="setting" />
        </ListItemIcon>
        <ListItemText primary="Profile" />
      </ListItemLink>
      <ListItem button key="Help" onClick={props.onClickHelpHandler}>
        <ListItemIcon>
          <SvgIcon iconName="help" />
        </ListItemIcon>
        <ListItemText primary="Help" />
      </ListItem>
      <ListItem button key="Sign out" onClick={props.onClickLogoutHandler}>
        <ListItemIcon>
          <SvgIcon iconName="run" />
        </ListItemIcon>
        <ListItemText primary="Sign out" />
      </ListItem>
    </List>
  );
};

export default HeaderList;
