import qs from 'qs';

export const getTokensFromUrl = (provider: string, urlSearchParams: string) => {
  const qsObj = qs.parse(urlSearchParams.substring(1));

  if (typeof qsObj.access_token === 'string') {
    return qsObj.access_token;
  }

  return null;
};
