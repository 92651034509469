import axios from 'axios';

import config from '../config';
import { msalInterceptor } from './auth/interceptor';
import { getLogger } from './Utilities';

const apiConfig = config.api;
const baseUrl = apiConfig.baseUrl;

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'correlation-id': 'webapp',
  },
});

instance.defaults.timeout = 30000;
instance.interceptors.request.use(msalInterceptor, (error) => {
  getLogger('msalInterceptor')('ERROR ' + error);
  throw error;
});

export default instance;
