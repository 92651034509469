import React, { createContext, useCallback, useMemo, useState } from 'react';
import { EditFarmPage } from '../Enum';
import {
  UserResourceAdd,
  UserResourceRemove,
  useSelectedFarm,
} from './SelectedFarmProvider';
import { getLogger } from '../Utilities';
import { useErrorHandler } from 'react-error-boundary';

interface EditFarmContextProps {
  loading: boolean;
  page: EditFarmPage;
  setPage: (p: EditFarmPage) => void;
  onSaveResourceMapping: (afterSave?: () => void) => void;
  markForAdd: (r: UserResourceAdd) => void;
  markForRemove: (r: UserResourceRemove) => void;
  processing: boolean;
}

const EditFarmContext = createContext({} as EditFarmContextProps);

const logger = getLogger('EditFarmProvider');

const EditFarmProvider: React.FC = (props) => {
  const { selectedFarm, updateMappings, loading } = useSelectedFarm();
  const handleError = useErrorHandler();
  const [processing, setProcessing] = useState<boolean>(false);
  const [page, setPage] = useState<EditFarmPage>(EditFarmPage.provider);
  const [userResourceRemoveList, setUserResourceRemoveList] = useState<
    UserResourceRemove[]
  >([]);
  const [userResourceAddList, setUserResourceAddList] = useState<
    UserResourceAdd[]
  >([]);

  const onSaveResourceMapping = useCallback(
    (afterSave?: () => void) => {
      const process = async (farmId: string) => {
        setProcessing(true);
        logger('OnSaveResourceMapping ' + farmId);
        await updateMappings(userResourceAddList, userResourceRemoveList);
        if (afterSave) {
          afterSave();
        }
      };
      if (!loading && selectedFarm) {
        process(selectedFarm.toString())
          .catch((err) => handleError(err))
          .finally(() => setProcessing(false));
      }
    },
    [
      handleError,
      loading,
      selectedFarm,
      updateMappings,
      userResourceAddList,
      userResourceRemoveList,
    ]
  );
  const markForAdd = useCallback(
    (r: UserResourceAdd) => {
      const exists =
        (userResourceAddList?.filter((it) => it.resourceId === r.resourceId)
          ?.length ?? 0) > 0;
      // remove from RemoveList
      setUserResourceRemoveList(
        userResourceRemoveList?.filter(
          (it) => it.resourceId !== r.resourceId
        ) ?? []
      );
      // if not existing then add
      if (!exists) {
        setUserResourceAddList([...userResourceAddList, r]);
      }
    },
    [userResourceAddList, userResourceRemoveList]
  );
  const markForRemove = useCallback(
    (r: UserResourceRemove) => {
      // remove from RemoveList
      setUserResourceAddList(
        userResourceAddList?.filter((it) => it.resourceId !== r.resourceId) ??
          []
      );
      // if not existing then add
      const exists =
        (userResourceRemoveList?.filter((it) => it.resourceId === r.resourceId)
          ?.length ?? 0) > 0;
      if (!exists) {
        setUserResourceRemoveList([...userResourceRemoveList, r]);
      }
    },
    [userResourceAddList, userResourceRemoveList]
  );
  const value: EditFarmContextProps = useMemo(() => {
    return {
      loading,
      page,
      onSaveResourceMapping,
      setPage,
      markForAdd,
      markForRemove,
      processing,
    };
  }, [
    loading,
    processing,
    markForAdd,
    markForRemove,
    onSaveResourceMapping,
    page,
  ]);
  return (
    <EditFarmContext.Provider value={value}>
      {props.children}
    </EditFarmContext.Provider>
  );
};

const useEditFarm = () => React.useContext(EditFarmContext);
export { useEditFarm, EditFarmProvider };
