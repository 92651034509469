import React, { createContext, useCallback, useMemo, useState } from 'react';
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

interface InfoAlert {
  message: string;
  variant?: 'success' | 'info';
}

interface AlertContextProps {
  showInfoAlert: (payload: InfoAlert) => void;
}
const anchor: SnackbarOrigin = { vertical: 'bottom', horizontal: 'left' };
const AlertContext = createContext({} as AlertContextProps);

const AlertProvider: React.FC = ({ children }) => {
  const [infoAlert, setInfoAlert] = useState<InfoAlert | null>(null);

  const showInfoAlert = useCallback((payload: InfoAlert) => {
    setInfoAlert(payload);
  }, []);

  const handleClose = useCallback(() => {
    setInfoAlert(null);
  }, []);

  const value = useMemo(() => {
    return {
      showInfoAlert,
    };
  }, [showInfoAlert]);

  return (
    <AlertContext.Provider value={value}>
      {infoAlert ? (
        <Snackbar
          data-testid={'yummy'}
          open={true}
          autoHideDuration={10000}
          onClose={handleClose}
          anchorOrigin={anchor}
        >
          <Alert
            onClose={handleClose}
            severity={infoAlert.variant ?? 'info'}
            elevation={1}
          >
            {infoAlert.message}
          </Alert>
        </Snackbar>
      ) : undefined}
      {children}
    </AlertContext.Provider>
  );
};
const useAlert = () => React.useContext(AlertContext);
export { AlertProvider, useAlert };
