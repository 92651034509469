import React, { CSSProperties, useCallback, useState } from 'react';
import { useAccount } from './utilities/context/AccountProvider';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { EndSessionRequest } from '@azure/msal-browser';
import { msalInstance } from './utilities/auth/msal';
import PagePadding from './components/container/PagePadding';
import ContentContainer from './components/container/ContentContainer';
import { FallbackProps } from 'react-error-boundary';

const btnStyle = { marginRight: '1rem' };
const stylePre = { whiteSpace: 'pre-wrap' } as CSSProperties;
export const ErrorDisplay: React.FC<FallbackProps> = (props) => {
  const { account } = useAccount();
  const [loading, setLoading] = useState(false);
  useDeepCompareEffect(() => {
    console.error(JSON.stringify(props.error));
  }, [props.error]);
  const logout = useCallback(async () => {
    const logOutRequest: EndSessionRequest = {
      account: account,
    };
    setLoading(true);
    localStorage.clear();
    try {
      await msalInstance.logoutRedirect(logOutRequest);
    } catch (e) {
      // delete all cookies
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
      window.location.href = '/';
    }
  }, [account]);
  const reload = useCallback(() => {
    window.location.reload();
  }, []);
  return (
    <PagePadding>
      <ContentContainer>
        <h1>An error occurred.</h1>
        <code>
          <pre style={stylePre}>{JSON.stringify(props.error)}</pre>
        </code>
        <footer>
          <button onClick={reload} style={btnStyle}>
            Reload
          </button>
          <button onClick={logout}>{loading ? 'Loading...' : 'Logout'}</button>
        </footer>
      </ContentContainer>
    </PagePadding>
  );
};
