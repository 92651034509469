import {
  ApplicationInsights,
  ICustomProperties,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import config from '../config';
import { sha256 } from 'js-sha256';

const instrumentationKey = config.insights.instrumentationKey;
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true,
  dataTags: {
    useDefaultContentNameOrId: true,
  },
};

const ai = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    extensions: [clickPluginInstance],
    extensionConfig: {
      [clickPluginInstance.identifier]: clickPluginConfig,
    },
  },
});

ai.loadAppInsights();
const appInsights = ai.appInsights;

const trackEvent = (event: string, customProperties?: ICustomProperties) => {
  if (customProperties) {
    appInsights.trackEvent({ name: event, properties: customProperties });
  } else {
    appInsights.trackEvent({ name: event });
  }
};

const trackException = (error: Error) => {
  appInsights.trackException({ exception: error });
};

const trackTrace = (message: string) => {
  appInsights.trackTrace({ message: message });
};
const setInsightAuth = (objectId: string, userId?: string) => {
  const securedUserId = sha256(objectId);
  ai.setAuthenticatedUserContext(securedUserId);
  if (userId) {
    ai.addTelemetryInitializer((envelope: ITelemetryItem) => {
      envelope.data = {};
      envelope.data.userId = userId;
    });
  } else {
    trackException(new Error('user Id is not defined from claims'));
  }
};

const clearInsightAuth = () => {
  ai.clearAuthenticatedUserContext();
  ai.addTelemetryInitializer(() => false);
};

const trackPageView = (
  name: string,
  uri: string,
  customProperties?: ICustomProperties
) => {
  if (customProperties) {
    appInsights.trackPageView({ name, uri, properties: customProperties });
  } else {
    appInsights.trackPageView({ name, uri });
  }
};

export {
  trackEvent,
  trackException,
  trackTrace,
  setInsightAuth,
  clearInsightAuth,
  trackPageView,
};
