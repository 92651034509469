import React, { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import CloseIcon from '@material-ui/icons/Close';

import { checkVarEqual } from '../../utilities/Utilities';
import {
  IconButtonType,
  PageNameEnum,
  AppInsightsEvent,
} from '../../utilities/Enum';
import whiteLogoImg from '../../assets/AgrigateWhite.png';
import BlueLogoImg from '../../assets/AgrigateBlue.png';
import { useAuth } from '../../utilities/context/AuthProvider';
import history from '../../utilities/history';
import { trackEvent } from '../../utilities/AppInsights';

import config from '../../config';
import HeaderList from './HeaderList';
import ContentContainer from '../container/ContentContainer';

const drawerWidth = 310;

export interface HeaderProps {
  /**
   * Text show on header
   */
  pageName: PageNameEnum | string | undefined;
  iconButton: IconButtonType;
  hideNavigation?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(3),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    clearButton: {
      padding: theme.spacing(2),
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(2),
    },
    logolg: {
      width: '121px',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    logoSml: {
      width: theme.spacing(10),
    },
    pageName: {
      fontWeight: 600,
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    buttonLg: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      '&:hover': {
        color: 'rgba(255,255,255,0.7)',
      },
      textTransform: 'none',
      color: 'white',
      fontSize: 16,
      fontWeight: 400,
      marginLeft: theme.spacing(1),
    },
    buttonLgGroup: {
      marginLeft: theme.spacing(8),
      display: 'flex',
      flexGrow: 1,
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        display: 'none',
      },
    },
    hightlightPageName: {
      fontWeight: 700,
    },
    hide: {
      display: 'none',
    },
  })
);

export const Header: React.FC<HeaderProps> = ({
  pageName,
  iconButton,
  hideNavigation,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { logout, account } = useAuth();
  const userId = account?.idTokenClaims.extension_UserId;
  const dashboardLink = `${config.legacy.dashboardUrl}/?redirectedFrom=core`;

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onClickFarmDataHandler = useCallback(() => {
    trackEvent(AppInsightsEvent.evtClickConnection, { userId });
  }, [userId]);

  const onClickProfileHandler = useCallback(() => {
    trackEvent(AppInsightsEvent.evtClickProfile, { userId });
  }, [userId]);

  const onClickDashboardHandler = useCallback(() => {
    trackEvent(AppInsightsEvent.evtClickDashboard, { userId });
    window.open(dashboardLink);
  }, [dashboardLink, userId]);

  const onClickHelpHandler = useCallback(() => {
    trackEvent(AppInsightsEvent.evtClickHelp, { userId });
    window.open('https://help.agrigate.co.nz/knowledge', '_blank');
  }, [userId]);

  const onClickLogoutHandler = useCallback(() => {
    trackEvent(AppInsightsEvent.evtClickSignout, { userId });
    logout();
  }, [logout, userId]);

  const onEsc = useCallback((e) => {
    if (e.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  const highlight = checkVarEqual(pageName);

  const renderPageName = (pageName: string | undefined): string | undefined => {
    const rawPageName: (string | undefined)[] = pageName?.includes('-')
      ? pageName.split('-')
      : [pageName];
    if (rawPageName.length > 1) {
      return `${_.startCase(rawPageName[0])} - ${_.startCase(rawPageName[1])}`;
    }
    return _.startCase(rawPageName[0]);
  };

  const onClose = useCallback(() => {
    history.push('/connection');
  }, []);

  const classesDrawer = useMemo(
    () => ({
      paper: classes.drawerPaper,
    }),
    [classes.drawerPaper]
  );

  const onBackdrop = useCallback(() => setOpen(false), []);

  return (
    <div className={classes.root}>
      <AppBar position="sticky">
        <ContentContainer>
          <Toolbar disableGutters={true}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx({
                [classes.menuButton]: true,
                [classes.hide]: iconButton !== IconButtonType.menu,
              })}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onClose}
              edge="start"
              className={clsx({
                [classes.menuButton]: true,
                [classes.hide]: iconButton !== IconButtonType.back,
              })}
            >
              <CloseIcon />
            </IconButton>
            <Link to={'/connection'}>
              <img
                src={whiteLogoImg}
                alt="Agrigate logo"
                className={classes.logolg}
              />
            </Link>
            <div
              className={clsx({
                [classes.buttonLgGroup]: true,
                [classes.hide]: hideNavigation,
              })}
            >
              <Button
                component={Link}
                to="/"
                className={clsx({
                  [classes.buttonLg]: true,
                  [classes.hightlightPageName]: highlight(
                    PageNameEnum.farmData
                  ),
                })}
                onClick={onClickFarmDataHandler}
              >
                Farm Data
              </Button>
              <Button
                className={clsx({
                  [classes.buttonLg]: true,
                  [classes.hightlightPageName]: highlight(
                    PageNameEnum.dashboard
                  ),
                })}
                onClick={onClickDashboardHandler}
              >
                Dashboard
              </Button>
              <Button
                component={Link}
                to="/profile"
                className={clsx({
                  [classes.buttonLg]: true,
                  [classes.hightlightPageName]: highlight(PageNameEnum.profile),
                })}
                onClick={onClickProfileHandler}
              >
                Profile
              </Button>
              <Button
                className={clsx({
                  [classes.buttonLg]: true,
                  [classes.hightlightPageName]: highlight(PageNameEnum.help),
                })}
                onClick={onClickHelpHandler}
              >
                Help
              </Button>
            </div>
            <Button
              className={clsx({
                [classes.buttonLg]: true,
                [classes.hide]: hideNavigation,
              })}
              onClick={onClickLogoutHandler}
            >
              Sign out
            </Button>
            <Typography variant="h6" noWrap className={classes.pageName}>
              {renderPageName(pageName)}
            </Typography>
          </Toolbar>
        </ContentContainer>
      </AppBar>
      <Drawer
        onBackdropClick={onBackdrop}
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        onKeyDown={onEsc}
        classes={classesDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            className={classes.clearButton}
            onClick={handleDrawerClose}
          >
            <ClearIcon color="primary" />
          </IconButton>
          <img
            src={BlueLogoImg}
            alt="Agrigate logo"
            className={classes.logoSml}
          />
        </div>
        <div
          role="presentation"
          onClick={handleDrawerClose}
          onKeyDown={handleDrawerClose}
        >
          <HeaderList
            onClickDashboardHandler={onClickDashboardHandler}
            onClickHelpHandler={onClickHelpHandler}
            onClickLogoutHandler={onClickLogoutHandler}
          />
        </div>
      </Drawer>
    </div>
  );
};
