import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ConnectionData,
  FarmData,
  getConnectionData,
  getFarmData,
} from '../../logic/ConnectionLogic';
import {
  addAndDeleteMappings,
  getFarmHerds,
  HerdProps,
} from '../../logic/EditFarmLogic';
import { AppInsightsEvent, ProviderCode, SupportedProviderCode } from '../Enum';
import {
  farmSourceMsalInstance,
  loginScopes,
  mindaMsalInstance,
} from '../auth/msal';
import useDeepCompareEffect, {
  useDeepCompareEffectNoCheck,
} from 'use-deep-compare-effect';
import { getLogger, getProviderNameByCode } from '../Utilities';
import { useErrorHandler } from 'react-error-boundary';
import {
  disconnectProvider as dataDisconnectProvider,
  UserResource,
} from '../../services/dataService';
import { trackEvent } from '../AppInsights';
import callConnectProvider from '../auth/connectProvider';
import callGetProviderResource from '../auth/getProviderResource';
import callGetConnections, {
  ProviderCodeMapping,
} from '../auth/getConnections';
import { useAlert } from './AlertProvider';
import config from '../../config';

interface SelectedFarmContextProps {
  updateSelectedFarm: (s: string | number | undefined) => void;
  selectedFarm: string | number | undefined;
  selectedProvider: SupportedProviderCode | null;
  setSelectedProvider: (p: SupportedProviderCode | null) => void;
  setShouldConnect: (b: boolean) => void;
  farmName: string;
  herds: HerdProps[];
  farmData: FarmData[];
  connectionData: ConnectionData[];
  loading: boolean;
  loadingProviderResources: boolean;
  connectedProviders: ProviderCode[];
  providerCodeMapping: ProviderCodeMapping[];
  disconnectProvider: (code: ProviderCode) => void;
  connectFarmProvider: (code: SupportedProviderCode) => void;
  disconnectedProviders: ProviderCode[];
  sharedProviders: ProviderCode[];
  pageLoading: boolean;
  providerResources: {
    provider: ProviderCode;
    categoryName: string;
    resources: UserResource[];
  }[];
  loadPage: () => void;
  updateMappings: (
    toAdd: UserResourceAdd[],
    toDelete: UserResourceRemove[]
  ) => Promise<void>;
}

export interface UserResourceAdd {
  farmId: string | number;
  resourceId: number;
  startDate: Date;
  endDate: Date | null;
}
export interface UserResourceRemove {
  farmId: string | number;
  resourceId: number;
}

interface SelectedFarmProviderProps {}
export interface GetProviderResource {
  provider: ProviderCode;
  categoryName: string;
  resources: UserResource[];
}
const logger = getLogger('SelectedFarmProvider');

const SelectedFarmContext = createContext({} as SelectedFarmContextProps);

const SelectedFarmProvider: React.FC<SelectedFarmProviderProps> = ({
  children,
}) => {
  const { showInfoAlert } = useAlert();
  const handleError = useErrorHandler();
  const [connectionData, setConnectionData] = useState<Array<ConnectionData>>(
    []
  );
  const [selectedProvider, setSelectedProvider] =
    useState<SupportedProviderCode | null>(null);
  const [providerCodeMapping, setProviderCodeMapping] = useState<
    ProviderCodeMapping[]
  >([]);
  const [farmData, setFarmData] = useState<Array<FarmData>>([]);
  const [herds, setHerds] = useState<Array<HerdProps>>([]);
  const [loading, setLoading] = useState(false);
  const [loadingProviderResources, setLoadingProviderResources] =
    useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedFarm, updateSelectedFarm] = useState<number | string>();
  const [connectedProviders, setConnectedProviders] = useState<
    Array<ProviderCode>
  >([]);
  const [disconnectedProviders, setDisconnectedProviders] = useState<
    Array<ProviderCode>
  >([]);
  const [sharedProviders, setSharedProviders] = useState<Array<ProviderCode>>(
    []
  );
  const [farmName, setFarmName] = useState('');

  const [shouldConnect, setShouldConnect] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [shouldFetchConnections, setShouldFetchConnections] = useState(false);
  const [shouldFetchProviderResources, setShouldFetchProviderResources] =
    useState(false);

  const [providerResources, setProviderResources] = useState<
    GetProviderResource[]
  >([]);

  const getProviderResource = useCallback(
    async (provider: ProviderCode) => {
      const farmId = selectedFarm!;
      const result: GetProviderResource[] = await callGetProviderResource(
        provider,
        farmId
      );
      setProviderResources(result);
      logger('Get provider resource ' + provider + ' farm' + farmId, result);
    },
    [selectedFarm]
  );

  const getConnections = useCallback(
    async (farm: number | string) => {
      const farmConnectionData = await getConnectionData(farm.toString());
      setFarmName(farmConnectionData.name);
      logger('Farm connection data', farmConnectionData.connections);
      const connections = farmConnectionData.connections;
      if (connections.length) {
        const {
          connected,
          disconnected,
          shared,
          providerCodeMapping: res,
        } = await callGetConnections(connectedProviders, connections);
        setConnectedProviders(connected);
        setDisconnectedProviders(disconnected);
        setSharedProviders(shared);
        setProviderCodeMapping(res);
      }
      setConnectionData(connections);
    },
    [connectedProviders]
  );

  useEffect(() => {
    if (!loading && shouldFetchConnections && selectedFarm) {
      logger('Should fetch connections');
      setLoading(true);
      setShouldFetchConnections(false);
      getConnections(selectedFarm)
        .catch((e) => handleError(e))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    getConnections,
    handleError,
    loading,
    selectedFarm,
    shouldFetchConnections,
  ]);

  useEffect(() => {
    if (shouldConnect && !loading && selectedProvider && selectedFarm) {
      logger('Should connect triggered');
      setShouldConnect(false);
      setLoading(true);
      setLoadingProviderResources(true);
      callConnectProvider(selectedProvider)
        .then(() => getProviderResource(selectedProvider))
        .catch((e) => handleError(e))
        .finally(() => {
          setLoading(false);
          setLoadingProviderResources(false);
        });
    }
  }, [
    getProviderResource,
    handleError,
    loading,
    selectedFarm,
    selectedProvider,
    shouldConnect,
  ]);

  useDeepCompareEffect(() => {
    if (
      !loading &&
      !loadingProviderResources &&
      selectedProvider &&
      selectedFarm &&
      shouldFetchProviderResources
    ) {
      logger('Get provider resource');
      setShouldFetchProviderResources(false);
      setLoadingProviderResources(true);
      getProviderResource(selectedProvider)
        .catch((e) => handleError(e))
        .finally(() => {
          setLoadingProviderResources(false);
        });
    }
  }, [
    getProviderResource,
    handleError,
    loading,
    loadingProviderResources,
    selectedFarm,
    selectedProvider,
    shouldFetchProviderResources,
  ]);

  const connectFarmProvider = useCallback(
    (provider: SupportedProviderCode) => {
      logger(`connectProvider ${provider}`);
      setLoading(true);
      setPageLoading(true);

      try {
        trackEvent(AppInsightsEvent.evtSigninRedirect);

        switch (provider) {
          case ProviderCode.lic:
            mindaMsalInstance
              .loginRedirect({
                scopes: loginScopes,
                redirectStartPage: `${window.location.origin}/connect/${ProviderCode.lic}`,
              })
              .catch((err: unknown) => handleError(err));
            break;
          case ProviderCode.fonterra:
            farmSourceMsalInstance
              .loginRedirect({
                scopes: loginScopes,
                redirectStartPage: `${window.location.origin}/connect/${ProviderCode.fonterra}`,
              })
              .catch((err: unknown) => handleError(err));
            break;
          case ProviderCode.figured:
            window.location.href = `${config.api.authUrl}`;
            break;
          default:
            throw new Error(
              `Unknown provider ${provider} during connect farm provider`
            );
        }
      } catch (e) {
        handleError(e);
        setLoading(false);
        setPageLoading(false);
      }
    },
    [handleError]
  );

  const fetchData = useCallback(async () => {
    const farmData: Array<FarmData> = await getFarmData();
    logger(`fetchData ${selectedFarm} farm data length ` + farmData?.length);
    setFarmData(farmData);
    if (farmData?.length > 0) {
      logger('Farm data has length');
      const farm = selectedFarm ?? farmData[0].id!;
      const herdsResult: Array<HerdProps> = await getFarmHerds(farm.toString());
      setHerds(herdsResult);
      updateSelectedFarm(farm);
      await getConnections(farm);
      if (selectedProvider) {
        await getProviderResource(selectedProvider);
      }
    } else {
      logger('SKIPPING fetch data farm data has no length');
    }
  }, [getConnections, getProviderResource, selectedFarm, selectedProvider]);

  useEffect(
    function setup() {
      if (!loading && shouldFetch) {
        logger('Setup fetch');
        setLoading(true);
        setLoadingProviderResources(true);
        setShouldFetch(false);
        fetchData()
          .catch((err) => {
            handleError(err);
          })
          .finally(() => {
            setLoading(false);
            setLoadingProviderResources(false);
          });
      }
    },
    [handleError, shouldFetch, fetchData, loading, setLoading, selectedFarm]
  );

  useEffect(
    function onFarmChange() {
      setShouldFetch(true);
      setShouldFetchConnections(true);
    },
    [selectedFarm]
  );

  useEffect(
    function onProv() {
      setShouldFetchProviderResources(true);
    },
    [selectedProvider]
  );

  const disconnectProvider = useCallback(
    async (provider: string) => {
      logger(`disconnectProvider ${provider}`);
      setLoading(true);
      try {
        await dataDisconnectProvider(provider);
        await fetchData();
      } catch (e) {
        handleError(e);
      } finally {
        setLoading(false);
        showInfoAlert({
          message: `Disconnected ${getProviderNameByCode(provider)}`,
          variant: 'info',
        });
      }
    },
    [fetchData, handleError, showInfoAlert]
  );

  useDeepCompareEffectNoCheck(
    function updateFarmName() {
      setFarmName(
        selectedFarm
          ? farmData?.find((f) => f.id === selectedFarm)?.name ?? ''
          : ''
      );
    },
    [selectedFarm, farmData]
  );

  const updateMappings = useCallback(
    async (toAdd: UserResourceAdd[], toDelete: UserResourceRemove[]) => {
      logger('Update mappings');
      try {
        setLoading(true);
        await addAndDeleteMappings(toAdd, toDelete);
        setLoadingProviderResources(true);
        await getProviderResource(selectedProvider!);
        showInfoAlert({
          message: 'Provider mappings updated',
          variant: 'success',
        });
      } catch (e) {
        handleError(e);
      } finally {
        setLoadingProviderResources(false);
        setLoading(false);
      }
    },
    [getProviderResource, handleError, selectedProvider, showInfoAlert]
  );

  const loadPage = useCallback(() => {
    setShouldFetch(true);
    setShouldFetchConnections(true);
  }, []);

  const value = useMemo(() => {
    const contextProps: SelectedFarmContextProps = {
      updateMappings,
      providerResources,
      providerCodeMapping,
      updateSelectedFarm,
      selectedFarm,
      selectedProvider,
      setSelectedProvider,
      farmName,
      herds,
      farmData,
      connectionData,
      connectedProviders,
      loading,
      setShouldConnect,
      loadPage,
      loadingProviderResources,
      disconnectProvider,
      disconnectedProviders,
      sharedProviders,
      connectFarmProvider,
      pageLoading,
    };
    return contextProps;
  }, [
    updateMappings,
    providerResources,
    providerCodeMapping,
    selectedFarm,
    selectedProvider,
    farmName,
    herds,
    farmData,
    connectionData,
    connectedProviders,
    loading,
    loadPage,
    loadingProviderResources,
    disconnectProvider,
    disconnectedProviders,
    sharedProviders,
    connectFarmProvider,
    pageLoading,
  ]);

  return (
    <SelectedFarmContext.Provider value={value}>
      {children}
    </SelectedFarmContext.Provider>
  );
};

const useSelectedFarm = () => React.useContext(SelectedFarmContext);
export { useSelectedFarm, SelectedFarmProvider };
