import React, { Ref } from 'react';
import { ListItemProps } from '@material-ui/core/ListItem';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';

export interface ListItemLinkProps
  extends ListItemProps<'a', { button?: true }> {
  to: string;
}

export const ListItemLink: React.FC<ListItemLinkProps> = ({ to, ...props }) => {
  const link = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link
          ref={ref as unknown as Ref<HTMLAnchorElement>}
          to={to}
          {...linkProps}
        />
      )),
    [to]
  );

  return <ListItem button component={link} {...props} />;
};
