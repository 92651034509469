import React, { useMemo } from 'react';
import Icon from '@material-ui/core/Icon';
import addFarmIcon from '../../assets/AddFarmIcon.svg';
import dataIcon from '../../assets/DateIcon.svg';
import farmIcon from '../../assets/FarmIcon.svg';
import helpIcon from '../../assets/HelpIcon.svg';
import linkIcon from '../../assets/LinkIcon.svg';
import linkIconGreen from '../../assets/LinkIconGreen.svg';
import linkIconGray from '../../assets/LinkIconGray.svg';
import runIcon from '../../assets/RunIcon.svg';
import settingIcon from '../../assets/SettingIcon.svg';
import shareIcon from '../../assets/ShareIcon.svg';
import visibilityIcon from '../../assets/VisibilityIcon.svg';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { IconProps } from '@material-ui/core/Icon';

type iconName =
  | 'addFarm'
  | 'date'
  | 'farm'
  | 'help'
  | 'link'
  | 'linkGreen'
  | 'linkGray'
  | 'run'
  | 'setting'
  | 'share'
  | 'visibility';

export interface AgIconProps extends IconProps {
  /**
   * Icon name
   */
  iconName: iconName;
}
const useStyles = makeStyles(() =>
  createStyles({
    iconRoot: {
      textAlign: 'center',
    },
    imageIcon: {
      display: 'flex',
      height: 'inherit',
      width: 'inherit',
    },
  })
);

export const SvgIcon: React.FC<AgIconProps> = ({
  fontSize = 'default',
  iconName,
  ...props
}) => {
  const classes = useStyles();
  const getSrcPath = (name: iconName) => {
    switch (name) {
      case 'addFarm':
        return addFarmIcon;
      case 'date':
        return dataIcon;
      case 'farm':
        return farmIcon;
      case 'help':
        return helpIcon;
      case 'link':
        return linkIcon;
      case 'linkGreen':
        return linkIconGreen;
      case 'linkGray':
        return linkIconGray;
      case 'run':
        return runIcon;
      case 'setting':
        return settingIcon;
      case 'share':
        return shareIcon;
      case 'visibility':
        return visibilityIcon;
    }
  };

  const className = useMemo(() => {
    return { root: classes.iconRoot };
  }, [classes.iconRoot]);
  return (
    <Icon fontSize={fontSize} classes={className} {...props}>
      <img
        className={classes.imageIcon}
        src={getSrcPath(iconName)}
        alt="icon"
      />
    </Icon>
  );
};
