import Helmet from 'react-helmet';
import React, { lazy, Suspense } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import theme from './style/theme';
import { AuthProvider } from './utilities/context/AuthProvider';
import PrivateRoute from './route/PrivateRoute';
import { Loading } from './components/loading/Loading';
import { PageProvider } from './components/pageProvider/PageProvider';
import history from './utilities/history';
import NotFound from './pages/notFound/NotFound';
import { SelectedFarmProvider } from './utilities/context/SelectedFarmProvider';
import { EditFarmProvider } from './utilities/context/EditFarmProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { AccountProvider } from './utilities/context/AccountProvider';
import { AlertProvider } from './utilities/context/AlertProvider';
import { ErrorDisplay } from './ErrorDisplay';

const Profile = lazy(() => import('./pages/profile/Profile'));
const Connection = lazy(() => import('./pages/connection/Connection'));
const EditFarm = lazy(() => import('./pages/editFarm/EditFarm'));

const ConnectNew = lazy(() => import('./pages/connectNew/ConnectNew'));

const buildVersion = process.env.REACT_APP_BUILD_VERSION ?? 'local';

function App() {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Helmet>
        <meta name="version" content={buildVersion} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <AccountProvider>
          <AlertProvider>
            <ErrorBoundary FallbackComponent={ErrorDisplay}>
              <AuthProvider>
                <SelectedFarmProvider>
                  <Suspense fallback={<Loading />}>
                    <Router history={history}>
                      <Switch>
                        <Route path="/" exact>
                          <Redirect to="/connection" />
                        </Route>
                        <PrivateRoute path="/profile" exact>
                          <PageProvider>
                            <Profile />
                          </PageProvider>
                        </PrivateRoute>
                        <PrivateRoute path="/connection" exact>
                          <PageProvider>
                            <Connection />
                          </PageProvider>
                        </PrivateRoute>
                        <PrivateRoute path="/connection/edit/:farmId" exact>
                          <PageProvider>
                            <EditFarmProvider>
                              <EditFarm />
                            </EditFarmProvider>
                          </PageProvider>
                        </PrivateRoute>
                        <PrivateRoute path="/connect/:provider" exact>
                          <PageProvider hideNavigation={true}>
                            <EditFarmProvider>
                              <ConnectNew />
                            </EditFarmProvider>
                          </PageProvider>
                        </PrivateRoute>
                        <PrivateRoute path="*">
                          <PageProvider>
                            <NotFound />
                          </PageProvider>
                        </PrivateRoute>
                      </Switch>
                    </Router>
                  </Suspense>
                </SelectedFarmProvider>
              </AuthProvider>
            </ErrorBoundary>
          </AlertProvider>
        </AccountProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
