import { ProviderCode, SupportedProviderCode } from '../Enum';
import { auth, farmSourceMsalInstance, mindaMsalInstance } from './msal';
import { AccountInfo } from '@azure/msal-browser';
import { TokenClaims } from '@azure/msal-common';
import { connectProvider } from '../../logic/ConnectionLogic';
import { getLogger } from '../Utilities';
import { getTokensFromUrl } from './getTokensFromUrl';

const logger = getLogger('callConnectProvider');
const callConnectProvider = async (selectedProvider: SupportedProviderCode) => {
  let policyName: string | null = null;
  let msalAccounts: AccountInfo[] | null = null;
  let oAuthApiToken: string | null = null;
  logger('callConnectProvider ' + selectedProvider);
  switch (selectedProvider) {
    case ProviderCode.fonterra: {
      msalAccounts = farmSourceMsalInstance.getAllAccounts();
      policyName = auth.names.farmsource;
      break;
    }

    case ProviderCode.lic: {
      msalAccounts = mindaMsalInstance.getAllAccounts();
      policyName = auth.names.minda;
      break;
    }
    case ProviderCode.figured: {
      const urlSearchParams = window.location.search;
      oAuthApiToken = getTokensFromUrl(selectedProvider, urlSearchParams);
      break;
    }

    default:
      throw new Error('No connected provider handler for ' + selectedProvider);
  }

  if (msalAccounts) {
    let accountToUse: AccountInfo | undefined;
    msalAccounts.every((account) => {
      const acct = account as AccountInfo;
      if (acct.idTokenClaims) {
        const idpToken = acct.idTokenClaims as TokenClaims & { tfp: string };
        if (idpToken.tfp === policyName) {
          accountToUse = account;
          return false;
        }
      }
      return true;
    });

    if (accountToUse) {
      if (accountToUse.idTokenClaims) {
        const idpToken = accountToUse.idTokenClaims as TokenClaims & {
          idp_access_token: string;
        };
        const idpAccessToken = idpToken.idp_access_token;
        if (idpAccessToken && selectedProvider) {
          await connectProvider(selectedProvider, idpAccessToken);
        }
      }
    } else {
      throw new Error('No account to use found in connect provider');
    }
  } else if (oAuthApiToken && selectedProvider) {
    await connectProvider(selectedProvider, oAuthApiToken);
  } else {
    throw new Error(
      'No msalAccounts or selected provider or oAuthApiToken during connect provider'
    );
  }
};

export default callConnectProvider;
