import React, { ReactElement, useCallback } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { useAuth } from '../utilities/context/AuthProvider';
import useHubspot from '../utilities/hooks/useHubspot';

interface privateRouteProps extends RouteProps {
  path: string;
  children: ReactElement;
}

const PrivateRoute: React.FC<privateRouteProps> = ({
  path,
  children,
  ...rest
}) => {
  const { isAuthenticated } = useAuth();

  useHubspot();

  const render = useCallback(
    () => (isAuthenticated ? <Route>{children} </Route> : null),
    [isAuthenticated, children]
  );

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
