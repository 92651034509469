import React, { useCallback, useMemo, useState } from 'react';
import { IconButtonType, PageNameEnum } from '../../utilities/Enum';
import { Header } from '../header/Header';

const PageContext = React.createContext({} as PageContextProps);

interface PageProviderProps {
  children: React.ReactNode;
  hideNavigation?: boolean;
}

interface PageContextProps {
  updateIconButton: (icon: IconButtonType) => void;
  updatePageName: (page: PageNameEnum | string) => void;
  getPageName: () => PageNameEnum | string | undefined;
}

const PageProvider: React.FC<PageProviderProps> = ({
  children,
  hideNavigation,
}) => {
  const [iconButton, setIconButton] = useState<IconButtonType>(
    IconButtonType.menu
  );
  const [pageName, setPageName] = useState<PageNameEnum | string>();

  const updateIconButton = (icon: IconButtonType) => {
    setIconButton(icon);
  };

  const updatePageName = (page: PageNameEnum | string) => {
    setPageName(page);
  };

  const getPageName = useCallback(() => {
    return pageName ? pageName.toLowerCase() : '';
  }, [pageName]);

  const value = useMemo(() => {
    return {
      updateIconButton,
      updatePageName,
      getPageName,
    };
  }, [getPageName]);

  return (
    <PageContext.Provider value={value}>
      <Header
        pageName={pageName}
        iconButton={iconButton}
        hideNavigation={hideNavigation}
      />
      {children}
    </PageContext.Provider>
  );
};

const usePage = () => React.useContext(PageContext);
export { PageProvider, usePage };
