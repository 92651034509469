import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      width: '100%',
    },
    wrapper: {
      width: '100%',
      maxWidth: '1200px',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  })
);

/**
 * Providers max width and side gutters for content but no vertical padding
 */
const ContentContainer: React.FC<{ className?: string }> = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.contentContainer + ' ' + (props.className || '')}>
      <div className={classes.wrapper}>{props.children}</div>
    </div>
  );
};
export default ContentContainer;
