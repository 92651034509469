import { ConnectionStatus, ProviderCode } from '../Enum';
import { ConnectionData, getProviderData } from '../../logic/ConnectionLogic';
import { getLogger, getMappingString } from '../Utilities';

export interface ProviderCodeMapping {
  provider: ProviderCode;
  mapping: string;
}

const logger = getLogger('callGetConnections');

async function callGetConnections(
  connectedProviders: ProviderCode[],
  connectionData: Array<ConnectionData>
): Promise<{
  connected: Array<ProviderCode>;
  disconnected: Array<ProviderCode>;
  shared: Array<ProviderCode>;
  providerCodeMapping: ProviderCodeMapping[];
}> {
  logger('Get provider data');
  const providers: Array<ProviderCode> = await getProviderData();
  const connected: Array<ProviderCode> = [];
  const disconnected: Array<ProviderCode> = [];
  const shared: Array<ProviderCode> = [];
  for (const d of connectionData) {
    if (providers.indexOf(d.code) > -1) {
      switch (d.status) {
        case ConnectionStatus.disconnected:
          disconnected.push(d.code);
          break;
        case ConnectionStatus.connected:
          connected.push(d.code);
          break;
        case ConnectionStatus.sharedData:
          shared.push(d.code);
          break;
      }
    }
  }
  logger('Get provider code mapping');
  const providerCodeMapping: ProviderCodeMapping[] = await Promise.all(
    connectedProviders.map(async (connectedProvider) => {
      logger(`getMappingString ${connectedProvider}`);
      return {
        provider: connectedProvider,
        mapping: await getMappingString(connectedProvider),
      };
    })
  );
  return {
    connected,
    disconnected,
    shared,
    providerCodeMapping,
  };
}
export default callGetConnections;
