import axios from '../utilities/Axios';
import { getLogger } from '../utilities/Utilities';

/**
 * TODO
 * - make farmId required
 * - use number for sourceId and targetId to be consistent
 */
interface GetProviderIntegrationParams {
  farmId: number | undefined;
  sourceId: string;
  targetId: string;
}

interface SetProviderIntegrationParams {
  farmId: number | string;
  sourceId: number;
  targetId: number;
  enabled: boolean;
  direction: number;
  integrationType: number;
}

interface GetProviderResourceParams {
  farmId: number | string;
  provider: string;
  categoryName: string;
}
export interface ProviderIntegration {
  providerIntegration: { enabled: boolean; isShareable?: boolean };
}
/**
 * TODO
 * - add response type
 * - handle error
 * - avoid returning empty string
 */
export const getProviderIntegration = async (
  params: GetProviderIntegrationParams
): Promise<ProviderIntegration | null> => {
  try {
    getLogger('getProviderIntegration')(
      `Fetch /provider-integration/${params.farmId}`
    );
    const result = await axios.get<ProviderIntegration>(
      `/provider-integration/${params.farmId}`,
      {
        params: {
          sourceId: params.sourceId,
          targetId: params.targetId,
        },
      }
    );
    return result.data;
  } catch (error) {
    getLogger('getProviderIntegration')(error);
    return null;
  }
};
export interface SetProviderIntegrationResult {
  message: string;
}
/**
 * TODO
 * - add response type
 * - handle error
 */
export const setProviderIntegration = async (
  params: SetProviderIntegrationParams
): Promise<SetProviderIntegrationResult | null> => {
  try {
    getLogger('setProviderIntegration')(
      `Post /provider-integration/${params.farmId}`
    );
    const res = await axios.post<SetProviderIntegrationResult>(
      `/provider-integration/${params.farmId}`,
      {
        direction: params.direction,
        enabled: params.enabled,
        sourceId: params.sourceId,
        targetId: params.targetId,
        integrationType: params.integrationType,
      }
    );
    return res.data;
  } catch (error) {
    getLogger('setProviderIntegration')(error);
    return null;
  }
};

/**
 * TODO
 * - add response type
 * - handle error
 * - avoid returning empty string
 */
export const getProviderResource = async (
  params: GetProviderResourceParams
): Promise<Array<UserResource>> => {
  try {
    const result = await axios.get<Array<UserResource>>(`/provider/resources`, {
      params: {
        farmId: params.farmId,
      },
      headers: {
        'data-provider-code': params.provider,
        'category-name': params.categoryName,
      },
    });
    getLogger('getProviderResource')(
      `Fetch /provider/resources provider ${params.provider} farm ${params.farmId}`,
      result.data
    );
    return result.data;
  } catch (error) {
    getLogger(`getProviderResource`)(error);
    return [];
  }
};

export interface UserResource {
  isMapped: boolean;
  mappedTo?: { code: string; name: string };
  endDate: Date;
  startDate: Date;
  friendlyName: string;
  resourceReference: string;
  resourceId: number;
  farmGroupId?: number;
  dataMappingId?: number;
}

/**
 * TODO
 * - add response type
 * - handle error
 * - avoid returning empty string
 */
export const disconnectProvider = async (
  providerCode: string
): Promise<{ status: number }> => {
  getLogger('disconnectProvider')(`Delete /provider/${providerCode}`);
  const result = await axios.delete(`/provider/${providerCode}`);
  return { status: result.status };
};
