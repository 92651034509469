import './effects';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './style/main.scss';
import * as serviceWorker from './utilities/sw/serviceWorkerRegistration';
import SwConfig from './utilities/sw/SwConfig';
import { RegisterConfig } from './utilities/sw/serviceWorkerRegistration';
import { enableLogging } from './utilities/Utilities';

enableLogging();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register(SwConfig as unknown as RegisterConfig);
