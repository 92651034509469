import axios from '../utilities/Axios';
import { ProviderCode } from '../utilities/Enum';
import {
  UserResourceAdd,
  UserResourceRemove,
} from '../utilities/context/SelectedFarmProvider';

export interface HerdProps {
  dataProviderId: number;
  resourceId: number;
  resourceReference: string;
  resourceType: string;
  friendlyName?: string;
}

export interface MappingProps {
  dataMappingId: number;
  resourceId: number;
  endDate: Date;
  farmGroupId: number;
  startDate: Date;
}

export const getFarmHerds = async (
  farmId: string
): Promise<Array<HerdProps>> => {
  return await axios
    .get(`/farms/${farmId}`, {
      headers: { 'resource-type': 'herd' },
    })
    .then((response) => {
      const data = response.data;
      return data;
    });
};

export const getProviderMapping = async (
  provider: ProviderCode,
  categoryName: string
): Promise<Array<MappingProps>> => {
  return axios
    .get(`/providers`, {
      headers: {
        'resource-type': 'herd',
        'data-provider-code': provider,
        'category-name': categoryName,
      },
    })
    .then((response) => {
      const data = response.data;
      return data;
    });
};

export const addMapping = async (
  farmId: string,
  resourceId: number,
  startDate: Date,
  endDate: Date | null
) => {
  return axios
    .post(`/farms/dataMapping`, {
      farmId,
      resourceId: resourceId,
      startDate,
      endDate,
    })
    .then((response) => {
      const data = response.data;
      return data;
    });
};

export const addAndDeleteMappings = async (
  toAdd: UserResourceAdd[],
  toDelete: UserResourceRemove[]
) => {
  await Promise.all(
    toAdd.map((it) =>
      addMapping(it.farmId.toString(), it.resourceId, it.startDate, it.endDate)
    )
  );
  await Promise.all(
    toDelete.map((it) => deleteMapping(it.resourceId, it.farmId))
  );
};

export const deleteMapping = async (
  resourceId: number,
  farmId: string | number
) => {
  return await axios
    .delete(`/farms/dataMapping`, {
      params: { resourceId: resourceId, farmId: farmId },
    })
    .then((response) => {
      const data = response.data;
      return data;
    });
};
