export enum PageNameEnum {
  farmData = 'farmData',
  dashboard = 'dashboard',
  profile = 'profile',
  help = 'help',
  connect = 'Connect',
}

export enum ConnectPanelContent {
  connectApp = 0,
  shareData = 1,
}

export enum ConnectionStatus {
  disconnected = 0,
  connected = 1,
  sharedData = 2,
}

export enum DirectionalShare {
  disconnect = 0,
  bidirectionalShare = 1,
  monoDirectionalShare = 2,
}

export enum ShareType {
  AnimalHealth = 0,
  MilkProductionQuality = 1,
}

export enum ProviderCode {
  farmax = 'farmax',
  farmiq = 'farmiq',
  fonterra = 'fonterra', //farm source
  lic = 'lic', //minda
  figured = 'figured',
  ravensdown = 'ravensdown',
  paysauce = 'paysauce',
  nzx = 'nzx',
  rezare = 'rezare',
}
// which provider codes can be connected
export type SupportedProviderCode =
  | ProviderCode.lic
  | ProviderCode.figured
  | ProviderCode.fonterra;

export enum ProviderId {
  figured = 1,
  fonterra = 2,
  lic = 3,
  ravensdown = 5,
  rezare = 8,
  paySauce = 9,
  nzx = 12,
  farmax = 16,
  farmIQ = 17,
}

export enum ShareDataName {
  animalHealth = 'Animal Health',
  milkProductionQuality = 'Milk Production & Quality',
}

export enum EditFarmPage {
  provider,
  form,
}

export enum IconButtonType {
  menu,
  back,
}

export enum CategoryName {
  farm = 'Farm',
  Accounts = 'Accounts',
  herd = 'Herd',
  pasture = 'Pasture',
  payroll = 'Payroll',
  supplementaryFeed = 'Supplementary Feed',
  milkProduction = 'Milk Production',
  health = 'Health',
  nutrients = 'Nutrients',
  animalIntake = 'animal-intake',
  feedOnOffer = 'feed-on-offer',
}

export enum AppInsightsEvent {
  evtClickSignout = 'evt-click-signout',
  evtSigninFailed = 'evt-signin-failed',
  evtSigninRedirect = 'evt-signin-redirect',
  evtClickConnection = 'evt-click-connection',
  evtClickProfile = 'evt-click-profile',
  evtClickDashboard = 'evt-click-dashboard',
  evtClickHelp = 'evt-click-help',
  evtConnectFarmax = 'evt-connect-farmax',
  evtConnectFarmaxFailed = 'evt-connect-farmax-failed',
  evtConnectFarmiq = 'evt-connect-farmiq',
  evtConnectFarmiqFailed = 'evt-connect-farmiq-failed',
  evtConnectHawkeye = 'evt-connect-hawkeye',
  evtConnectHawkeyeFailed = 'evt-connect-hawkeye-failed',
  evtSharedataLicFarmsource = 'evt-sharedata-lic-farmsource',
  evtSharedataLicFarmsourceFailed = 'evt-sharedata-lic-farmsource-failed',
  evtSharedataFarmsourceFarmiq = 'evt-sharedata-farmsource-farmiq',
  evtSharedataFarmsourceFarmiqFailed = 'evt-sharedata-farmsource-farmiq-failed',
  pvConnection = 'pv-connection',
  pvFarm = 'pv-farm',
  evtUpdateFarm = 'evt-update-farm',
  evtUpdateFarmFailed = 'evt-update-farm-failed',
  evtUpdateFarmCancelled = 'evt-update-farm-cancelled',
  pvProfile = 'pv-profile',
  evtUpdateProfileCancelled = 'evt-update-profile-cancelled',
  evtUpdateProfile = 'evt-update-profile',
  evtUpdateProfileFailed = 'evt-update-profile-failed',
  evtUpdateProfileUploadImage = 'evt-update-profile-upload-image',
  evtUpdateProfileUploadImageFailed = 'evt-update-profile-upload-image-failed',
}
