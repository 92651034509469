import config from '../../config';
import * as msal from '@azure/msal-browser';

export const auth = config.auth;
const msalConfig = {
  auth: {
    clientId: auth.clientId,
    authority: auth.authorities.signUpSignIn,
    knownAuthorities: [auth.knowAuthorities],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
const mindaMsalConfig = {
  auth: {
    clientId: auth.clientId,
    authority: auth.authorities.minda,
    knownAuthorities: [auth.knowAuthorities],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const farmSourceMsalConfig = {
  auth: {
    clientId: auth.clientId,
    authority: auth.authorities.farmsource,
    knownAuthorities: [auth.knowAuthorities],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const resetPasswordMsalConfig = {
  auth: {
    clientId: auth.clientId,
    authority: auth.authorities.resetPassword,
    knownAuthorities: [auth.knowAuthorities],
  },
};
const readScope = `https://${auth.tenantName}.onmicrosoft.com/${auth.clientId}/Read`;
const writeScope = `https://${auth.tenantName}.onmicrosoft.com/${auth.clientId}/Write`;

export const loginScopes = ['openid', 'profile', readScope, writeScope];
export const msalInstance = new msal.PublicClientApplication(msalConfig);
export const mindaMsalInstance = new msal.PublicClientApplication(
  mindaMsalConfig
);
export const resetPasswordMsalInstance = new msal.PublicClientApplication(
  resetPasswordMsalConfig
);
export const farmSourceMsalInstance = new msal.PublicClientApplication(
  farmSourceMsalConfig
);
